import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c(VTextField,{staticClass:"mx-2",attrs:{"label":"Search Reports","prepend-inner-icon":"mdi-magnify","solo":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"rfaccent2","loading":_vm.loading},on:{"click":_vm.fetchReports}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1),(_vm.error.state)?_c(VAlert,{staticClass:"ma-3",attrs:{"type":"error","colored-border":"","border":"left","dense":""}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e()],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.reports,"loading":_vm.loading,"server-items-length":_vm.total,"search":_vm.search,"options":_vm.options,"footer-props":{itemsPerPageOptions: [15, 25, 50, 75, 100]},"mobile-breakpoint":0,"sort-by":"created","sort-desc":"","dense":"","must-sort":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.reporter_username",fn:function(ref){
var item = ref.item;
return [_c('UserAvatar',{staticClass:"mr-1",attrs:{"user":{username: item.reporter_username, callsign: item.reporter_callsign},"xsmall":""}}),_vm._v(" "+_vm._s(item.reporter_username)+" "),_c('small',[_vm._v("("+_vm._s(item.reporter_callsign)+")")])]}},{key:"item.commentId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/admin/comments/" + (item.commentId))}},[_vm._v(_vm._s(item.commentId))])]}},{key:"item.repeater",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/repeater/" + (item.repeater))}},[_vm._v(_vm._s(item.repeater))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [(item.created)?_c('span',[_vm._v(_vm._s(_vm.$moment.utc(item.created).format('M/D/YYYY'))+" "),_c('small',[_vm._v("("+_vm._s(_vm.$moment.utc(item.created).fromNow())+")")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error","disabled":!item.commentId},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteComment(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Comment")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.resolveReport(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Resolve Report")])])]}}])}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Delete Comment")]),_c(VCardText,[_vm._v(" Are you sure you want to delete this comment? This action cannot be undone. "),(_vm.deleteDialog.item)?_c('div',{staticClass:"mt-4 pa-3 rounded",class:_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'},[_c('div',[_c('strong',[_vm._v("Comment ID:")]),_vm._v(" "+_vm._s(_vm.deleteDialog.item.commentId))]),(_vm.deleteDialog.item.comment)?_c('div',[_c('strong',[_vm._v("Content:")]),_vm._v(" "+_vm._s(_vm.deleteDialog.item.comment))]):_vm._e(),_c('div',[_c('strong',[_vm._v("Reported by:")]),_vm._v(" "+_vm._s(_vm.deleteDialog.item.username))]),_c('div',[_c('strong',[_vm._v("Reason:")]),_vm._v(" "+_vm._s(_vm.deleteDialog.item.reason))])]):_vm._e()]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteDialog.show = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"error","text":"","loading":_vm.deleteDialog.loading},on:{"click":_vm.deleteComment}},[_vm._v(" Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }