<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-text-field
                v-model="search"
                label="Search Comments"
                prepend-inner-icon="mdi-magnify"
                solo
                hide-details
                clearable
                dense
                class="mx-2"
            ></v-text-field>

            <v-btn 
                color="rfaccent2" 
                class="mr-2"
                @click="fetchComments"
                :loading="loading"
            >
                <v-icon left>mdi-refresh</v-icon>
                Refresh
            </v-btn>
            
            <v-alert 
                type="error" 
                colored-border 
                border="left" 
                dense 
                v-if="error.state"
                class="ma-3"
            >
                {{ error.message }}
            </v-alert>
        </div>

        <v-data-table
            :headers="headers"
            :items="comments"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 25, 50, 75, 100]}"
            :mobile-breakpoint="0"
            sort-by="date"
            sort-desc
            dense
            must-sort
            @click:row="rowClick"
            class="elevation-0"
        >

            <template v-slot:item.username="{ item }">
                <UserAvatar :user="{username: item.username, callsign: item.callsign}" xsmall class="mr-1"/>
                {{ item.username }}&nbsp;<small>({{ item.callsign }})</small>
            </template>

            <template v-slot:item.repeater="{ item }">
                <router-link :to="`/repeater/${item.repeater}`">{{ item.repeater }}</router-link>
            </template>

            <template v-slot:item.date="{ item }">
                <span v-if="item.date">{{ $moment.utc(item.date).format('M/D/YYYY') }} <small v-if="$vuetify.breakpoint.lgAndUp">({{ $moment.utc(item.date).fromNow() }})</small></span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from "@/config";
    import UserAvatar from "@/components/UserAvatar";

    export default {
        props: ['user'],

        components: { UserAvatar },

        data: () => ({
            headers: [
                {text: 'ID', value: 'id', sortable: false},
                {text: 'Username', value: 'username', width: 250},
                {text: 'Date', value: 'date', width: 200},
                {text: 'Comment', value: 'comment', sortable: false},
                {text: 'Repeater', value: 'repeater', sortable: false},
            ],
            comments: [],
            total: null,
            search: null,
            loading: false,
            options: {},
            showOldListings: true,
            showOfflineListings: true,
            error: {
                state: false,
                message: null
            }
        }),

        watch: {
            search() {
                this.fetchComments();
            },

            options: {
                handler() {
                    this.fetchComments();
                },
                deep: true
            }
        },

        methods: {
            async fetchComments() {
                try {
                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/comments?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.comments = response.data.items;
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    } else {
                        this.error.message = 'An error occurred while searching for comments.';
                        this.error.state = true;
                    }
                }
            },

            rowClick() {
                // Placeholder for future functionality
            }
        },

        mounted() {
            this.fetchComments();

            if (this.$router.currentRoute.name === 'admin-comments' && this.$router.currentRoute.params.id) this.search = this.$router.currentRoute.params.id;
        }
    }
</script>
