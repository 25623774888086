<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-text-field
                v-model="search"
                label="Search Reports"
                prepend-inner-icon="mdi-magnify"
                solo
                hide-details
                clearable
                dense
                class="mx-2"
            ></v-text-field>

            <v-btn 
                color="rfaccent2" 
                class="mr-2"
                @click="fetchReports"
                :loading="loading"
            >
                <v-icon left>mdi-refresh</v-icon>
                Refresh
            </v-btn>
            
            <v-alert 
                type="error" 
                colored-border 
                border="left" 
                dense 
                v-if="error.state"
                class="ma-3"
            >
                {{ error.message }}
            </v-alert>
        </div>

        <v-data-table
            :headers="headers"
            :items="reports"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 25, 50, 75, 100]}"
            :mobile-breakpoint="0"
            sort-by="created"
            sort-desc
            dense
            must-sort
            @click:row="rowClick"
            class="elevation-0"
        >
            <template v-slot:item.reporter_username="{ item }">
                <UserAvatar :user="{username: item.reporter_username, callsign: item.reporter_callsign}" xsmall class="mr-1"/>
                {{ item.reporter_username }}&nbsp;<small>({{ item.reporter_callsign }})</small>
            </template>

            <template v-slot:item.commentId="{ item }">
                <router-link :to="`/admin/comments/${item.commentId}`">{{ item.commentId }}</router-link>
            </template>

            <template v-slot:item.repeater="{ item }">
                <router-link :to="`/repeater/${item.repeater}`">{{ item.repeater }}</router-link>
            </template>

            <template v-slot:item.created="{ item }">
                <span
                    v-if="item.created">{{ $moment.utc(item.created).format('M/D/YYYY') }} <small>({{ $moment.utc(item.created).fromNow() }})</small></span>
            </template>
            
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            icon 
                            small 
                            color="error" 
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="confirmDeleteComment(item)"
                            :disabled="!item.commentId"
                        >
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Delete Comment</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            icon 
                            small 
                            color="success" 
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="resolveReport(item)"
                        >
                            <v-icon small>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Resolve Report</span>
                </v-tooltip>
            </template>
        </v-data-table>
        
        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteDialog.show" max-width="500px">
            <v-card>
                <v-card-title class="headline">Delete Comment</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this comment? This action cannot be undone.
                    <div v-if="deleteDialog.item" class="mt-4 pa-3 rounded" 
                         :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'">
                        <div><strong>Comment ID:</strong> {{ deleteDialog.item.commentId }}</div>
                        <div v-if="deleteDialog.item.comment"><strong>Content:</strong> {{ deleteDialog.item.comment }}</div>
                        <div><strong>Reported by:</strong> {{ deleteDialog.item.username }}</div>
                        <div><strong>Reason:</strong> {{ deleteDialog.item.reason }}</div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteDialog.show = false">Cancel</v-btn>
                    <v-btn 
                        color="error" 
                        text 
                        @click="deleteComment"
                        :loading="deleteDialog.loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from "@/config";
    import UserAvatar from "@/components/UserAvatar";

    export default {
        props: ['user'],

        components: { UserAvatar },

        data: () => ({
            headers: [
                {text: 'ID', value: 'id', sortable: false},
                {text: 'Username', value: 'reporter_username', width: 250},
                {text: 'Date', value: 'created', width: 200},
                {text: 'Reason', value: 'reason'},
                {text: 'Details', value: 'details', sortable: false},
                {text: 'Comment', value: 'comment', sortable: false},
                {text: 'Repeater', value: 'repeater', sortable: false},
                {text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '100px'}
            ],
            reports: [],
            total: null,
            search: null,
            loading: false,
            options: {},
            showOldListings: true,
            showOfflineListings: true,
            error: {
                state: false,
                message: null
            },
            deleteDialog: {
                show: false,
                item: null,
                loading: false
            }
        }),

        watch: {
            search() {
                this.fetchReports();
            },

            options: {
                handler() {
                    this.fetchReports();
                },
                deep: true
            }
        },

        methods: {
            async fetchReports() {
                try {
                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/comment/reports?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.reports = response.data.items;
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    } else {
                        this.error.message = 'An error occurred while searching for reports.';
                        this.error.state = true;
                    }
                }
            },

            rowClick() {
                // Placeholder for future functionality
            },
            
            confirmDeleteComment(item) {
                this.deleteDialog.item = item;
                this.deleteDialog.show = true;
            },
            
            async deleteComment() {
                if (!this.deleteDialog.item || !this.deleteDialog.item.commentId) return;
                
                this.deleteDialog.loading = true;
                
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;
                    
                    await axios.delete(`${config.API_LOCATION}/comment/${this.deleteDialog.item.commentId}`, {
                        headers: axiosHeaders
                    });
                    
                    // Also resolve the report
                    await this.resolveReport(this.deleteDialog.item, false);
                    
                    // Show success message
                    this.$root.$emit('showSnackbar', {
                        text: 'Comment deleted and report resolved successfully',
                        color: 'success'
                    });
                    
                    // Refresh the reports list
                    this.fetchReports();
                } catch (err) {
                    console.error('Error deleting comment:', err);
                    
                    this.$root.$emit('showSnackbar', {
                        text: 'Error deleting comment: ' + (err.response?.data?.message || err.message || 'Unknown error'),
                        color: 'error'
                    });
                } finally {
                    this.deleteDialog.loading = false;
                    this.deleteDialog.show = false;
                    this.deleteDialog.item = null;
                }
            },
            
            async resolveReport(item, showMessage = true) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;
                    
                    await axios.put(`${config.API_LOCATION}/comment/report/${item.id}/resolve`, {}, {
                        headers: axiosHeaders
                    });
                    
                    if (showMessage) {
                        this.$root.$emit('showSnackbar', {
                            text: 'Report marked as resolved',
                            color: 'success'
                        });
                        
                        // Refresh the reports list
                        this.fetchReports();
                    }
                } catch (err) {
                    console.error('Error resolving report:', err);
                    
                    if (showMessage) {
                        this.$root.$emit('showSnackbar', {
                            text: 'Error resolving report: ' + (err.response?.data?.message || err.message || 'Unknown error'),
                            color: 'error'
                        });
                    }
                }
            }
        },

        mounted() {
            this.fetchReports();
        }
    }
</script>
