import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('h1',{staticClass:"page-title font-weight-light mb-4"},[_vm._v("Admin Panel")]),(_vm.user.authenticated && _vm.isAdmin(_vm.user.role))?_c('div',[_c(VCard,{staticClass:"mb-5"},[_c(VTabs,{attrs:{"color":"white","background-color":"rfaccent2","fixed-tabs":"","centered":"","icons-and-text":"","show-arrows":""},on:{"change":_vm.handleTabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,[_vm._v(" Stats "),_c(VIcon,[_vm._v("mdi-chart-bar")])],1),_c(VTab,[_vm._v(" News "),_c(VIcon,[_vm._v("mdi-newspaper")])],1),_c(VTab,[_vm._v(" Users "),_c(VIcon,[_vm._v("mdi-account-group")])],1),_c(VTab,[_vm._v(" Repeaters "),_c(VIcon,[_vm._v("mdi-radio-tower")])],1),_c(VTab,[_vm._v(" Comments "),_c(VIcon,[_vm._v("mdi-comment-text-multiple")])],1),_c(VTab,[_vm._v(" Reports "),_c(VIcon,[_vm._v("mdi-flag")])],1)],1),_c(VTabsItems,{staticClass:"mt-2",attrs:{"touchless":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTabItem,[_c('Stats',{attrs:{"user":_vm.user},on:{"unauthorized":_vm.handleUnauthorized}})],1),_c(VTabItem,[_c('News',{attrs:{"user":_vm.user},on:{"unauthorized":_vm.handleUnauthorized}})],1),_c(VTabItem,[_c('Users',{attrs:{"user":_vm.user},on:{"unauthorized":_vm.handleUnauthorized}})],1),_c(VTabItem,[_c('Repeaters',{attrs:{"user":_vm.user},on:{"unauthorized":_vm.handleUnauthorized}})],1),_c(VTabItem,[_c('Comments',{attrs:{"user":_vm.user},on:{"unauthorized":_vm.handleUnauthorized}})],1),_c(VTabItem,[_c('Reports',{attrs:{"user":_vm.user},on:{"unauthorized":_vm.handleUnauthorized}})],1)],1)],1)],1):_c('div',[_c(VAlert,{staticClass:"mt-5",attrs:{"type":"error","prominent":""}},[_vm._v(" You must be an administrator to view this page. ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }