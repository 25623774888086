<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-text-field
                v-model="search"
                label="Search Repeaters"
                prepend-inner-icon="mdi-magnify"
                solo
                hide-details
                clearable
                dense
                class="mx-2"
            ></v-text-field>

            <v-btn 
                color="rfaccent2" 
                class="mr-2"
                @click="fetchRepeaters"
                :loading="loading"
            >
                <v-icon left>mdi-refresh</v-icon>
                Refresh
            </v-btn>
            
            <v-alert 
                type="error" 
                colored-border 
                border="left" 
                dense 
                v-if="error.state"
                class="ma-3"
            >
                {{ error.message }}
            </v-alert>
        </div>

        <v-data-table
            :headers="headers"
            :items="repeaters"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 25, 50, 75, 100]}"
            :mobile-breakpoint="0"
            sort-by="Modified"
            sort-desc
            must-sort
            show-expandx
            single-expand
            dense
            item-key="ID"
            :expanded.sync="expanded"
            @click:row="rowClick"
            class="elevation-0"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <Repeater :authUser="user" :repeater="item"/>
                </td>
            </template>

            <template v-slot:item.Modified="{ item }">
                <span v-if="item.Modified">{{$moment.utc(item.Modified).format('M/D/YYYY')}} <small>({{$moment.utc(item.Modified).fromNow()}})</small></span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from '@/config';
    import Repeater from '@/components/Repeater';

    export default {
        props: ['user'],

        components: {Repeater},

        data: () => ({
            headers: [
                {text: 'ID', value: 'ID'},
                {text: 'Name', value: 'Name'},
                {text: 'Location', value: 'Location'},
                {text: 'State', value: 'State'},
                {text: 'Frequency', value: 'Frequency'},
                {text: 'Type', value: 'Type'},
                {text: 'Owner', value: 'Owner'},
                {text: 'Status', value: 'Status'},
                {text: 'Modified', value: 'Modified'},
            ],
            repeaters: [],
            expanded: [],
            total: null,
            search: null,
            loading: false,
            options: {},
            showOldListings: true,
            showOfflineListings: true,
            error: {
                state: false,
                message: null
            }
        }),

        watch: {
            search() {
                this.fetchRepeaters();
            },

            options: {
                handler() {
                    this.fetchRepeaters();
                },
                deep: true
            }
        },

        methods: {
            async fetchRepeaters() {
                try {
                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/repeaters?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;
                    if (this.frequency) query += '&frequency=' + this.frequency.replace('462', '46X');
                    if (this.state) query += `&state=${this.state}`;
                    if (this.type) query += `&type=${this.type}`;
                    if (this.showOldListings) query += `&outdated=true`;
                    if (this.showOfflineListings) query += `&offline=true`;
                    if (this.latitude) query += `&latitude=${this.latitude}`;
                    if (this.longitude) query += `&longitude=${this.longitude}`;
                    if (this.latitude && this.longitude) query += `&range=25`; //default is 10

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.repeaters = response.data.items;
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    } else {
                        this.error.message = 'An error occurred while searching for repeaters.';
                        this.error.state = true;
                    }
                }
            },

            rowClick(row) {
                if (this.expanded.length > 0 && this.expanded[0] === row) this.expanded = [];
                else this.expanded = [row];
            },
        },

        mounted() {
            this.fetchRepeaters();
        }
    }
</script>
