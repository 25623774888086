<template>
    <v-card>
        <v-card-title class="font-weight-light d-flex justify-space-between">
            <span>System Statistics</span>
            <v-btn 
                color="rfaccent2" 
                small
                @click="loadStats"
                :loading="loading"
            >
                <v-icon left>mdi-refresh</v-icon>
                Refresh
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div class="text-center" v-if="loading">
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </div>

            <div v-else-if="stats.recent">
                <!-- User Statistics Section -->
                <v-card class="mb-5">
                    <v-card-title class="rfaccent2 white--text">
                        <v-icon left color="white">mdi-account-group</v-icon>
                        User Statistics
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <div class="d-flex flex-wrap justify-space-around">
                            <v-card dark color="rfaccent2" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.users }}</span>
                                    <span>Total Users</span>
                                </v-card-title>
                            </v-card>

                            <v-card dark color="rfaccent2" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.activeMonthlyUsers }}</span>
                                    <span>Monthly Active Users</span>
                                </v-card-title>
                            </v-card>

                            <v-card dark color="rfaccent2" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.activeYearlyUsers }}</span>
                                    <span>Yearly Active Users</span>
                                </v-card-title>
                            </v-card>
                        </div>
                        
                        <!-- User Trend Charts -->
                        <div class="d-flex flex-wrap justify-space-around mt-4">
                            <v-card
                                class="text-center mb-3"
                                color="rfaccent2"
                                dark
                                width="48%"
                                min-width="300"
                            >
                                <v-card-text>
                                    <v-sheet color="rgba(0, 0, 0, .12)">
                                        <v-sparkline
                                            :value="historyUsers"
                                            color="rgba(255, 255, 255, 1)"
                                            height="100"
                                            padding="24"
                                            stroke-linecap="round"
                                            smooth
                                        >
                                            <template v-slot:label="item">
                                                {{ parseInt(item.value).toLocaleString('en-US') }}
                                            </template>
                                        </v-sparkline>
                                    </v-sheet>
                                </v-card-text>

                                <v-card-text>
                                    <div class="text-h5 font-weight-thin">Total Users Trend</div>
                                </v-card-text>
                            </v-card>

                            <v-card
                                class="text-center mb-3"
                                color="rfaccent2"
                                dark
                                width="48%"
                                min-width="300"
                            >
                                <v-card-text>
                                    <v-sheet color="rgba(0, 0, 0, .12)">
                                        <v-sparkline
                                            :value="historyActiveMonthlyUsers"
                                            color="rgba(255, 255, 255, 1)"
                                            height="100"
                                            padding="24"
                                            stroke-linecap="round"
                                            smooth
                                        >
                                            <template v-slot:label="item">
                                                {{ parseInt(item.value).toLocaleString('en-US') }}
                                            </template>
                                        </v-sparkline>
                                    </v-sheet>
                                </v-card-text>

                                <v-card-text>
                                    <div class="text-h5 font-weight-thin">Monthly Active Users Trend</div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-card-text>
                </v-card>

                <!-- Repeater Statistics Section -->
                <v-card class="mb-5">
                    <v-card-title class="success darken-1 white--text">
                        <v-icon left color="white">mdi-radio-tower</v-icon>
                        Repeater Statistics
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <div class="d-flex flex-wrap justify-space-around">
                            <v-card dark color="success darken-1" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.repeaters }}</span>
                                    <span>Total Repeaters</span>
                                </v-card-title>
                            </v-card>

                            <v-card dark color="success darken-1" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.activeRepeaters }}</span>
                                    <span>Active Repeaters</span>
                                </v-card-title>
                            </v-card>

                            <v-card dark color="success darken-1" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.states }}</span>
                                    <span>States Covered</span>
                                </v-card-title>
                            </v-card>
                        </div>
                        
                        <!-- Repeater Trend Charts -->
                        <div class="d-flex flex-wrap justify-space-around mt-4">
                            <v-card
                                class="text-center mb-3"
                                color="success darken-1"
                                dark
                                width="48%"
                                min-width="300"
                            >
                                <v-card-text>
                                    <v-sheet color="rgba(0, 0, 0, .12)">
                                        <v-sparkline
                                            :value="historyRepeaters"
                                            color="rgba(255, 255, 255, 1)"
                                            height="100"
                                            padding="24"
                                            stroke-linecap="round"
                                            smooth
                                        >
                                            <template v-slot:label="item">
                                                {{ parseInt(item.value).toLocaleString('en-US') }}
                                            </template>
                                        </v-sparkline>
                                    </v-sheet>
                                </v-card-text>

                                <v-card-text>
                                    <div class="text-h5 font-weight-thin">Total Repeaters Trend</div>
                                </v-card-text>
                            </v-card>

                            <v-card
                                class="text-center mb-3"
                                color="success darken-1"
                                dark
                                width="48%"
                                min-width="300"
                            >
                                <v-card-text>
                                    <v-sheet color="rgba(0, 0, 0, .12)">
                                        <v-sparkline
                                            :value="historyActiveRepeaters"
                                            color="rgba(255, 255, 255, 1)"
                                            height="100"
                                            padding="24"
                                            stroke-linecap="round"
                                            smooth
                                        >
                                            <template v-slot:label="item">
                                                {{ parseInt(item.value).toLocaleString('en-US') }}
                                            </template>
                                        </v-sparkline>
                                    </v-sheet>
                                </v-card-text>

                                <v-card-text>
                                    <div class="text-h5 font-weight-thin">Active Repeaters Trend</div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-card-text>
                </v-card>

                <!-- License Statistics Section -->
                <v-card class="mb-5">
                    <v-card-title class="purple white--text">
                        <v-icon left color="white">mdi-certificate</v-icon>
                        License Statistics
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <div class="d-flex flex-wrap justify-space-around">
                            <v-card dark color="purple" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.activeLicenses }}</span>
                                    <span>Active Licenses</span>
                                </v-card-title>
                            </v-card>

                            <v-card dark color="purple" class="ma-2 elevation-3 stat-card">
                                <v-card-title class="font-weight-light d-flex flex-column">
                                    <span class="display-1 font-weight-regular">{{ stats.recent.newLicenses }}</span>
                                    <span>New Licenses</span>
                                </v-card-title>
                            </v-card>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </v-card-text>
    </v-card>
</template>

<style>
    /*.grid-container {*/
    /*    display: grid;*/
    /*    gap: 1em;*/
    /*    grid-template-columns: repeat(auto-fit, minmax(10em, 25em));*/
    /*}*/

    .grid-container {
        display: grid;
        gap: 1em;
    }

    @media (min-width: 35em) {
        .grid-container {
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
        }
    }

    .stat-card {
        min-width: 200px;
        flex: 1;
    }
</style>

<script>
    import axios from 'axios';
    import config from "@/config";

    export default {
        props: ['user'],

        data: () => ({
            loading: false,
            stats: {
                recent: {
                    activeLicenses: null,
                    activeRepeaters: null,
                    activeUsers: null,
                    newLicenses: null,
                    repeaters: null,
                    networks: null,
                    states: null,
                    users: null
                },
                history: [],
            },
        }),

        computed: {
            historyActiveMonthlyUsers() {
                return _.map(this.stats.history, o => {
                    return o.activeMonthlyUsers || 0;
                });
            },

            historyActiveYearlyUsers() {
                return _.map(this.stats.history, o => {
                    return o.activeYearlyUsers || 0;
                });
            },

            historyActiveRepeaters() {
                return _.map(this.stats.history, 'activeRepeaters');
            },

            historyUsers() {
                return _.map(this.stats.history, 'users');
            },

            historyRepeaters() {
                return _.map(this.stats.history, 'repeaters');
            },

            historyStates() {
                return _.map(this.stats.history, 'states');
            },

            // historyNetworks() {
            //     return _.map(this.stats.history, 'networks');
            // }
        },

        methods: {
            loadStats() {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/stats', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        // Update the recent stats
                        this.stats.recent = {
                            activeLicenses: response.data.recent.activeLicenses.toLocaleString('en-US'),
                            activeRepeaters: response.data.recent.activeRepeaters.toLocaleString('en-US'),
                            activeMonthlyUsers: response.data.recent.activeMonthlyUsers.toLocaleString('en-US'),
                            activeYearlyUsers: response.data.recent.activeYearlyUsers.toLocaleString('en-US'),
                            newLicenses: response.data.recent.newLicenses.toLocaleString('en-US'),
                            repeaters: response.data.recent.repeaters.toLocaleString('en-US'),
                            states: response.data.recent.states.toLocaleString('en-US'),
                            users: response.data.recent.users.toLocaleString('en-US')
                        };
                        
                        // Update the history data for charts
                        this.stats.history = response.data.history;
                        
                        // Force chart components to re-render
                        this.$forceUpdate();
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            console.error('Error loading stats:', err);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },

        mounted() {
            this.loadStats();
        },
    }
</script>
