<template>
    <v-container fluid>
        <h1 class="page-title font-weight-light mb-4">Admin Panel</h1>

        <div v-if="user.authenticated && isAdmin(user.role)">
            <v-card class="mb-5">
                <v-tabs
                    v-model="activeTab"
                    color="white"
                    background-color="rfaccent2"
                    fixed-tabs
                    centered
                    icons-and-text
                    show-arrows
                    @change="handleTabChange"
                >
                    <v-tab>
                        Stats
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-tab>
                    <v-tab>
                        News
                        <v-icon>mdi-newspaper</v-icon>
                    </v-tab>
                    <v-tab>
                        Users
                        <v-icon>mdi-account-group</v-icon>
                    </v-tab>
                    <v-tab>
                        Repeaters
                        <v-icon>mdi-radio-tower</v-icon>
                    </v-tab>
                    <v-tab>
                        Comments
                        <v-icon>mdi-comment-text-multiple</v-icon>
                    </v-tab>
                    <v-tab>
                        Reports
                        <v-icon>mdi-flag</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab" touchless class="mt-2">
                    <v-tab-item>
                        <Stats :user="user" @unauthorized="handleUnauthorized"/>
                    </v-tab-item>
                    <v-tab-item>
                        <News :user="user" @unauthorized="handleUnauthorized"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Users :user="user" @unauthorized="handleUnauthorized"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Repeaters :user="user" @unauthorized="handleUnauthorized"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Comments :user="user" @unauthorized="handleUnauthorized"/>
                    </v-tab-item>
                    <v-tab-item>
                        <Reports :user="user" @unauthorized="handleUnauthorized"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </div>

        <div v-else>
            <v-alert type="error" prominent class="mt-5">
                You must be an administrator to view this page.
            </v-alert>
        </div>
    </v-container>
</template>

<script>
    import Stats from '@/components/Admin/Stats.vue';
    import News from '@/components/Admin/News.vue';
    import Users from '@/components/Admin/Users.vue';
    import Repeaters from '@/components/Admin/Repeaters.vue';
    import Comments from '@/components/Admin/Comments.vue';
    import Reports from '@/components/Admin/Reports.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        props: ['user'],

        components: {Stats, News,Users, Repeaters, Comments, Reports},

        data: () => ({
            activeTab: 0,
            tabRoutes: [
                '/admin',
                '/admin/news',
                '/admin/users',
                '/admin/repeaters',
                '/admin/comments',
                '/admin/reports'
            ]
        }),

        methods: {
            isAdmin(role) {
                return role === 0;
            },
            
            handleUnauthorized() {
                this.$emit('unauthorized');
            },
            
            handleTabChange(tabIndex) {
                const route = this.tabRoutes[tabIndex];
                if (this.$route.path !== route) {
                    this.$router.push(route);
                }
            },
            
            setActiveTabFromRoute() {
                const currentPath = this.$route.path;
                
                // Special case for the root admin path
                if (currentPath === '/admin') {
                    this.activeTab = 0;
                    return;
                }
                
                // For other paths, find the matching route
                for (let i = 0; i < this.tabRoutes.length; i++) {
                    if (currentPath.includes(this.tabRoutes[i]) && this.tabRoutes[i] !== '/admin') {
                        this.activeTab = i;
                        return;
                    }
                }
                
                // Default to first tab if no match
                this.activeTab = 0;
            }
        },

        mounted() {
            useTitle(`Admin Panel - myGMRS.com`);
            this.setActiveTabFromRoute();
        },

        watch: {
            '$route.path'() {
                this.setActiveTabFromRoute();
            }
        }
    }
</script>
