<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-text-field
                v-model="search"
                label="Search News"
                prepend-inner-icon="mdi-magnify"
                solo
                hide-details
                clearable
                dense
                autocomplete="off"
                class="mx-2"
            ></v-text-field>

            <v-btn 
                color="rfaccent2" 
                class="mr-2"
                @click="fetchNews"
                :loading="loading"
            >
                <v-icon left>mdi-refresh</v-icon>
                Refresh
            </v-btn>
            
            <v-alert 
                type="error" 
                colored-border 
                border="left" 
                dense 
                v-if="error.state"
                class="ma-3"
            >
                {{ error.message }}
            </v-alert>
        </div>

        <v-data-table
            :headers="headers"
            :items="news"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 25, 50, 75, 100]}"
            :mobile-breakpoint="0"
            sort-by="date"
            sort-desc
            must-sort
            dense
            item-key="ID"            
            class="elevation-0"
        >
            <template v-slot:item.username="{ item }">
                <UserAvatar :user="{username: item.username, callsign: item.callsign}" xsmall class="mr-1"/>
                {{ item.username }} <small>({{ item.callsign }})</small>
            </template>

            <template v-slot:item.author="{ item }">
                <UserAvatar :user="{username: item.username, callsign: item.callsign}" xsmall class="mr-1"/>
                <!-- {{ item.username }} <small>({{ item.callsign }})</small> -->
                {{ item.firstName }} {{ item.lastName }} <small>({{ item.callsign }})</small>
            </template>

            <template v-slot:item.date="{ item }">
                {{ moment(item.date).format('MM/DD/YYYY hh:mma') }}
            </template>

            <template v-slot:item.body="{ item }">
                <span v-html="item.body"></span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn icon @click="editNews(item)">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            multi-line
            top
        >
            <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from "@/config";
    import UserAvatar from "@/components/UserAvatar";
    import moment from 'moment';
    // import { VueEditor } from 'vue2-editor';

    const CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    export default {
        // components: {UserAvatar, VueEditor},
        components: {UserAvatar},

        props: ['user'],

        data: () => ({
            headers: [
                {text: 'ID', value: 'id', width: 70},
                {text: 'Date', value: 'date', width: 175},
                {text: 'Author', value: 'author', width: 250},
                {text: 'Title', value: 'title', width: 300},
                {text: 'Body', value: 'body', sortable: false},
                {text: 'Actions', value: 'actions', width: 80,sortable: false},
            ],
            news: [],
            total: null,
            search: '',
            loading: false,
            options: {},
            error: {
                state: false,
                message: null
            },
            dialogs: {
            },
            snackbar: {
                state: false,
                color: null,
                timeout: 3000,
                text: null,
                icon: null
            }
        }),

        watch: {
            search: _.throttle(function () {
                this.fetchNews();
            }, 500),

            options: {
                handler() {
                    this.fetchNews();
                },
                deep: true
            }
        },

        methods: {
            async fetchNews() {
                try {
                    // Cancel any existing request regardless of loading state
                    if (source) {
                        source.cancel('Operation canceled due to new request.');
                        source = CancelToken.source();
                    }

                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/news?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders,
                        cancelToken: source.token
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.news = response.data.news;
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    if (!axios.isCancel(err)) {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            console.error(err);
                            this.error.message = 'An error occurred while searching for news.';
                            this.error.state = true;
                        }
                    }
                }
            },

            moment(date) {
                return moment(date);
            },
        },

        mounted() {

        }
    }
</script>