import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c(VTextField,{staticClass:"mx-2",attrs:{"label":"Search News","prepend-inner-icon":"mdi-magnify","solo":"","hide-details":"","clearable":"","dense":"","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"rfaccent2","loading":_vm.loading},on:{"click":_vm.fetchNews}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1),(_vm.error.state)?_c(VAlert,{staticClass:"ma-3",attrs:{"type":"error","colored-border":"","border":"left","dense":""}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e()],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.news,"loading":_vm.loading,"server-items-length":_vm.total,"search":_vm.search,"options":_vm.options,"footer-props":{itemsPerPageOptions: [15, 25, 50, 75, 100]},"mobile-breakpoint":0,"sort-by":"date","sort-desc":"","must-sort":"","dense":"","item-key":"ID"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('UserAvatar',{staticClass:"mr-1",attrs:{"user":{username: item.username, callsign: item.callsign},"xsmall":""}}),_vm._v(" "+_vm._s(item.username)+" "),_c('small',[_vm._v("("+_vm._s(item.callsign)+")")])]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [_c('UserAvatar',{staticClass:"mr-1",attrs:{"user":{username: item.username, callsign: item.callsign},"xsmall":""}}),_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" "),_c('small',[_vm._v("("+_vm._s(item.callsign)+")")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format('MM/DD/YYYY hh:mma'))+" ")]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.body)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.editNews(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])}),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout,"multi-line":"","top":""},model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[(_vm.snackbar.icon)?_c(VIcon,{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.snackbar.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }