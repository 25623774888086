<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-text-field
                v-model="search"
                label="Search Users"
                prepend-inner-icon="mdi-magnify"
                solo
                hide-details
                clearable
                dense
                class="mx-2"
            ></v-text-field>

            <v-btn 
                color="rfaccent2" 
                class="mr-2"
                @click="fetchUsers"
                :loading="loading"
            >
                <v-icon left>mdi-refresh</v-icon>
                Refresh
            </v-btn>
            
            <v-alert 
                type="error" 
                colored-border 
                border="left" 
                dense 
                v-if="error.state"
                class="ma-3"
            >
                {{ error.message }}
            </v-alert>
        </div>

        <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [15, 25, 50, 75, 100]}"
            :mobile-breakpoint="0"
            sort-by="Last Login"
            sort-desc
            must-sort
            single-expand
            dense
            item-key="ID"
            :expanded.sync="expanded"
            @click:row="rowClick"
            class="elevation-0"
        >
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <User :authUser="user" :username="item.Username" :key="item.Username"/>
                    </td>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="openUserDialog(item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-account-edit</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Edit User</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="viewSubscriptions(item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-credit-card-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>View Subscriptions</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="sendResetPassword(item.Username)">
                                <v-list-item-icon>
                                    <v-icon>mdi-key-variant</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Reset Password</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="confirmBanUser(item, !item.Banned)">
                                <v-list-item-icon>
                                    <v-icon>mdi-cancel</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title v-if="item.Banned">Unban User</v-list-item-title>
                                <v-list-item-title v-else>Ban User</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="confirmDeleteUser(item)">
                                <v-list-item-icon>
                                    <v-icon>mdi-close</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Delete Account</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:item.Username="{ item }">
                    <UserAvatar :user="{username: item.Username, callsign: item.Callsign}" xsmall class="mr-1"/>
                    {{ item.Username }} <small>({{ item.Callsign }})</small>
                </template>

                <template v-slot:item.Banned="{ item }">
                    <v-icon v-if="item.Banned === 1" color="error">mdi-account-cancel</v-icon>
                </template>

                <template v-slot:item.Verified="{ item }">
                    <v-icon v-if="item.Verified === 1" color="success">mdi-account-check</v-icon>
                </template>

                <template v-slot:item.PasswordExpired="{ item }">
                    <v-icon v-if="item.PasswordExpired === 1" color="error">mdi-clock-alert-outline</v-icon>
                </template>

                <template v-slot:item.StripeCustomer="{ item }">
                    <a
                        :href="`https://dashboard.stripe.com/customers/${item.StripeCustomer}`"
                        :target="`stripe_${item.StripeCustomer}`"
                    >
                        {{ item.StripeCustomer }}</a>
                </template>

                <template v-slot:item.SubscriptionEndDate="{ item }">
                    <span v-if="item.SubscriptionEndDate">
                        <span v-if="$vuetify.breakpoint.lgAndUp">
                            {{ $moment.unix(item.SubscriptionEndDate).format('M/D/YYYY') }}
                            <small>({{ $moment.unix(item.SubscriptionEndDate).fromNow() }})</small>
                        </span>
                        <span v-else>
                            {{ $moment.unix(item.SubscriptionEndDate).fromNow() }}
                        </span>
                    </span>
                </template>

                <template v-slot:item.LastLogin="{ item }">
                    <span v-if="item.LastLogin">
                        <span v-if="$vuetify.breakpoint.lgAndUp">
                            {{ $moment(item.LastLogin).format('M/D/YYYY') }}
                            <small>({{ $moment(item.LastLogin).fromNow() }})</small>
                        </span>
                        <span v-else>
                            {{ $moment(item.LastLogin).fromNow() }}
                        </span>
                    </span>
                </template>

                <template v-slot:item.Role="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon 
                                v-if="item.Role === 0" 
                                color="primary" 
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account-tie
                            </v-icon>
                            <v-icon 
                                v-else-if="item.Role === 1" 
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account
                            </v-icon>
                            <v-icon 
                                v-else 
                                color="warning"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-help-circle
                            </v-icon>
                        </template>
                        <span v-if="item.Role === 0">Administrator</span>
                        <span v-else-if="item.Role === 1">User</span>
                        <span v-else>Unknown Role ({{ item.Role }})</span>
                    </v-tooltip>
                </template>
            </v-data-table>

        <v-dialog v-model="dialogs.editUser.state" fullscreend max-width="1000">
            <v-card v-if="dialogs.editUser.user">
                <v-card-title class="font-weight-light px-0 pt-0">
                    <v-toolbar dense color="rfaccent2" dark>
                        Edit User: {{ dialogs.editUser.user.Username }} ({{ dialogs.editUser.user.Callsign }})
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeUserDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pt-5">

                    <v-form>
                        <v-text-field
                            v-model="dialogs.editUser.user.ID"
                            label="ID"
                            outlined
                            disabled
                            dense
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.user.Username"
                            :placeholder="dialogs.editUser.origUser.Username"
                            label="Username"
                            outlined
                            dense
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.user.Email"
                            :placeholder="dialogs.editUser.origUser.Email"
                            label="Email Address"
                            outlined
                            dense
                        ></v-text-field>

                        <div class="d-flex justify-space-between">
                            <v-text-field
                                v-model="dialogs.editUser.user.Password"
                                label="Password"
                                type="password"
                                outlined
                                dense
                            ></v-text-field>
                            <v-btn color="rfaccent2" dark class="ml-5" @click="generatePassword">
                                <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                                Generate
                            </v-btn>
                        </div>

                        <v-text-field
                            v-model="dialogs.editUser.user.Callsign"
                            :placeholder="dialogs.editUser.origUser.Callsign"
                            label="Callsign"
                            outlined
                            dense
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.LastLoginFormatted"
                            label="Last Login"
                            prepend-inner-icon="mdi-clock-outline"
                            outlined
                            dense
                            readonly
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.user.StripeCustomer"
                            :placeholder="dialogs.editUser.origUser.StripeCustomer"
                            label="Stripe Customer ID"
                            outlined
                            dense
                            readonly
                        ></v-text-field>

                        <v-menu
                            v-model="dialogs.editUser.menuSubscription"
                            :close-on-content-click="false"
                            offset-y
                            max-width="290"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dialogs.editUser.user.SubscriptionEndDate"
                                    :placeholder="dialogs.editUser.origUser.SubscriptionEndDate"
                                    label="Subscription Ends"
                                    prepend-inner-icon="mdi-alarm"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <v-card>
                                <v-date-picker
                                    v-model="dialogs.editUser.user.SubscriptionEndDate"
                                    show-adjacent-months
                                ></v-date-picker>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="rfaccent2" @click="dialogs.editUser.user.SubscriptionEndDate = ''">
                                        Clear
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>

                        <v-select
                            v-model="dialogs.editUser.user.Role"
                            label="User Role"
                            :items="userRoles"
                            outlined
                            dense
                        ></v-select>

                        <div class="d-flex justify-space-around mb-5">
                            <div>
                                <v-switch
                                    v-model="dialogs.editUser.user.Digest"
                                    label="Repeater Digest Emails"
                                    inset
                                    dense
                                    hide-details
                                ></v-switch>

                                <v-switch
                                    v-model="dialogs.editUser.user.Requests"
                                    label="Repeater Request Emails"
                                    inset
                                    dense
                                    hide-details
                                ></v-switch>

                                <v-switch
                                    v-model="dialogs.editUser.user.Verified"
                                    label="Email Verified"
                                    color="success"
                                    inset
                                    dense
                                    hide-details
                                ></v-switch>
                            </div>
                            <div>
                                <v-switch
                                    v-model="dialogs.editUser.user.Banned"
                                    label="Banned"
                                    color="error"
                                    inset
                                    dense
                                    hide-details
                                ></v-switch>

                                <v-switch
                                    v-model="dialogs.editUser.user.PasswordExpired"
                                    label="Password Expired"
                                    color="warning"
                                    inset
                                    dense
                                    hide-details
                                ></v-switch>
                            </div>
                        </div>

                        <v-sheet light class="mb-5 elevation-5">
                            <vue-editor v-model="dialogs.editUser.user.Profile" label="Profile"></vue-editor>
                        </v-sheet>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-btn @click="closeUserDialog">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="rfaccent2" @click="saveUser">
                        Save
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Subscriptions Dialog -->
        <v-dialog 
            v-model="dialogs.subscriptions.state" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            content-class="subscription-dialog-wrapper"
        >
            <v-card v-if="dialogs.subscriptions.user" class="subscription-dialog">
                <v-toolbar dense color="rfaccent2" dark>
                    <v-btn icon @click="closeSubscriptionsDialog">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Subscriptions: {{ dialogs.subscriptions.user.Username }} ({{ dialogs.subscriptions.user.Callsign }})
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeSubscriptionsDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                
                <v-card-text class="pt-5">
                    <v-data-table
                        :headers="subscriptionHeaders"
                        :items="dialogs.subscriptions.items"
                        :loading="dialogs.subscriptions.loading"
                        :no-data-text="dialogs.subscriptions.noDataText"
                        dense
                        class="elevation-1"
                    >
                        <template v-slot:item.id="{ item }">
                            <a
                                :href="`https://dashboard.stripe.com/subscriptions/${item.id}`"
                                :target="`stripe_sub_${item.id}`"
                            >
                                {{ item.id }}
                            </a>
                        </template>
                        
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                :color="getSubscriptionStatusColor(item.status)"
                                small
                                label
                                text-color="white"
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                        
                        <template v-slot:item.current_period_start="{ item }">
                            {{ $moment.unix(item.current_period_start).format('M/D/YYYY') }}
                        </template>
                        
                        <template v-slot:item.current_period_end="{ item }">
                            {{ $moment.unix(item.current_period_end).format('M/D/YYYY') }}
                            <small>({{ $moment.unix(item.current_period_end).fromNow() }})</small>
                        </template>
                        
                        <template v-slot:item.created="{ item }">
                            {{ $moment.unix(item.created).format('M/D/YYYY') }}
                        </template>
                        
                        <template v-slot:item.canceled_at="{ item }">
                            <span v-if="item.canceled_at">
                                {{ $moment.unix(item.canceled_at).format('M/D/YYYY') }}
                            </span>
                            <span v-else>-</span>
                        </template>
                        
                        <template v-slot:item.ended_at="{ item }">
                            <span v-if="item.ended_at">
                                {{ $moment.unix(item.ended_at).format('M/D/YYYY') }}
                            </span>
                            <span v-else>-</span>
                        </template>
                        
                        <template v-slot:item.amount_total="{ item }">
                            <span v-if="item.amount_total">
                                {{ formatCurrency(item.amount_total, item.currency) }}
                            </span>
                            <span v-else>-</span>
                        </template>
                        
                        <template v-slot:item.cancel_at_period_end="{ item }">
                            <v-icon v-if="item.cancel_at_period_end" color="warning">mdi-check</v-icon>
                            <span v-else>-</span>
                        </template>
                        
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                small
                                icon
                                :href="`https://dashboard.stripe.com/invoices/${item.latest_invoice}`"
                                target="_blank"
                                v-if="item.latest_invoice"
                            >
                                <v-icon small>mdi-receipt</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            multi-line
            top
        >
            <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from "@/config";
    import UserAvatar from "@/components/UserAvatar";
    import User from "@/components/User";
    import { VueEditor } from 'vue2-editor';
    import md5 from 'md5';

    const CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let subscriptionSource = CancelToken.source();

    export default {
        components: {UserAvatar, User, VueEditor},

        props: ['user'],

        data: () => ({
            headers: [
                {text: 'ID', value: 'ID', width: 70},
                {text: 'Username', value: 'Username', width: 250},
                {text: 'Email', value: 'Email', width: 300},
                {text: 'Role', value: 'Role'},
                {text: 'Tier', value: 'SubscriptionTier'},
                {text: 'Banned', value: 'Banned'},
                {text: 'Verified', value: 'Verified'},
                {text: 'Password Expired', value: 'PasswordExpired'},
                {text: 'Stripe Customer', value: 'StripeCustomer'},
                {text: 'Subscription Ends', value: 'SubscriptionEndDate'},
                {text: 'Last Login', value: 'Last Login'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            subscriptionHeaders: [
                {text: 'ID', value: 'id', width: 200},
                {text: 'Status', value: 'status', width: 100},
                {text: 'Tier', value: 'tier', width: 100},
                {text: 'Start Date', value: 'current_period_start', width: 120},
                {text: 'End Date', value: 'current_period_end', width: 180},
                {text: 'Created', value: 'created', width: 120},
                {text: 'Canceled', value: 'canceled_at', width: 120},
                {text: 'Ended', value: 'ended_at', width: 120},
                {text: 'Amount', value: 'amount_total', width: 100},
                {text: 'Cancel at Period End', value: 'cancel_at_period_end', width: 100},
                {text: 'Actions', value: 'actions', sortable: false, width: 80},
            ],
            users: [],
            expanded: [],
            total: null,
            search: '',
            loading: false,
            options: {},
            userRoles: [
                {text: 'Administrator', value: 0},
                {text: 'User', value: 1},
            ],
            error: {
                state: false,
                message: null
            },
            dialogs: {
                editUser: {
                    state: false,
                    user: null,
                    menuSubscription: false,
                    LastLoginFormatted: null,
                    origUser: null
                },
                subscriptions: {
                    state: false,
                    user: null,
                    items: [],
                    loading: false,
                    noDataText: 'No subscriptions found'
                }
            },
            snackbar: {
                state: false,
                color: null,
                timeout: 3000,
                text: null,
                icon: null
            }
        }),

        watch: {
            search: _.throttle(function () {
                this.fetchUsers();
            }, 500),

            options: {
                handler() {
                    this.fetchUsers();
                },
                deep: true
            }
        },

        methods: {
            generatePassword() {
                this.dialogs.editUser.user.Password = btoa(md5(Date.now())).substring(0, 10);

                navigator.clipboard.writeText(this.dialogs.editUser.user.Password);

                this.snackbar.text = 'New password copied to clipboard!';
                this.snackbar.color = 'success';
                this.snackbar.state = true;
            },

            async fetchUsers() {
                try {
                    // Cancel any existing request regardless of loading state
                    if (source) {
                        source.cancel('Operation canceled due to new request.');
                        source = CancelToken.source();
                    }

                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/users?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders,
                        cancelToken: source.token
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.users = this.$_.map(response.data.items, o => {
                        o.LastLogin = o['Last Login'];
                        delete o['Last Login'];
                        return o;
                    });
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    if (!axios.isCancel(err)) {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            console.error(err);
                            this.error.message = 'An error occurred while searching for users.';
                            this.error.state = true;
                        }
                    }
                }
            },

            rowClick(row) {
                if (this.expanded.length > 0 && this.expanded[0] === row) this.expanded = [];
                else this.expanded = [row];
            },

            async saveUser() {
                try {
                    const updatedUser = _.omit(this.dialogs.editUser.user, ['SubscriptionEndDate', 'LastLogin', 'SubscriptionTier']);
                    
                    if (updatedUser.Password && updatedUser.Password.trim() === '') delete updatedUser.Password;

                    // Omit StripeCustomer if it's empty, null, or undefined
                    if (!updatedUser.StripeCustomer) {
                        delete updatedUser.StripeCustomer;
                    }

                    updatedUser.Banned = updatedUser.Banned ? 1 : 0;
                    updatedUser.Verified = updatedUser.Verified ? 1 : 0;
                    updatedUser.PasswordExpired = updatedUser.PasswordExpired ? 1 : 0;
                    updatedUser.Digest = updatedUser.Digest ? 1 : 0;
                    updatedUser.Requests = updatedUser.Requests ? 1 : 0;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.put(`${config.API_LOCATION}/user/${updatedUser.ID}`, updatedUser, {
                        headers: axiosHeaders
                    });

                    console.log(response.data);

                    await this.fetchUsers();

                    this.snackbar.state = false;
                    this.snackbar.color = 'success';
                    this.snackbar.icon = 'mdi-check';
                    this.snackbar.text = 'User updated successfully';
                    this.snackbar.state = true;

                    this.closeUserDialog();
                } catch (err) {
                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    } else {
                        console.error(err);
                        this.snackbar.state = false;
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-close';
                        this.snackbar.text = err.response?.data?.message || err.message || 'An unknown error occurred: ' + err;
                        this.snackbar.state = true;
                    }
                }
            },

            openUserDialog(user) {
                this.dialogs.editUser.user = _.clone(user);
                this.dialogs.editUser.origUser = user;
                this.dialogs.editUser.LastLoginFormatted = this.$moment(user.LastLogin).format('M/D/YYYY h:m:sa');
                this.dialogs.editUser.state = true;
            },

            closeUserDialog() {
                this.dialogs.editUser.state = false;
                this.dialogs.editUser.user = null;
            },

            async sendResetPassword(username) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.post(`${config.API_LOCATION}/reset-password`, {
                        identity: username
                    }, {
                        headers: axiosHeaders
                    });

                    if (response.data.success) {
                        this.snackbar.state = false;
                        this.snackbar.color = 'success';
                        this.snackbar.icon = 'mdi-check';
                        this.snackbar.text = 'Password reset email was sent successfully.';
                        this.snackbar.state = true;
                    } else {
                        throw new Error(response.data.message || 'Password reset email failed!');
                    }
                } catch (err) {
                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-close';
                    this.snackbar.text = err.message || 'An unknown error occurred: ' + err;
                    this.snackbar.state = true;
                }
            },

            confirmBanUser(user, ban) {
                const confirm = window.confirm(`Are you sure you want to ${ban ? 'ban' : 'unban'} ${user.Username}?`);
                if (confirm) this.banUser(user, ban);
            },

            async banUser(user, ban) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const updatedUser = _.omit(user, ['LastLogin', 'SubscriptionEndDate', 'SubscriptionTier']);
                    updatedUser.Banned = ban ? 1 : 0;

                    const response = await axios.put(`${config.API_LOCATION}/user/${user.ID}`, updatedUser, {
                        headers: axiosHeaders
                    });

                    if (response.data.success) {
                        let foundUser = _.findIndex(this.users, {ID: user.ID});
                        if (foundUser !== -1) this.users[foundUser].Banned = updatedUser.Banned;

                        this.snackbar.state = false;
                        this.snackbar.color = 'success';
                        this.snackbar.icon = 'mdi-check';
                        this.snackbar.text = `User was ${ban ? 'banned' : 'unbanned'} successfully.`;
                        this.snackbar.state = true;
                    } else {
                        throw new Error(response.data.message || `User ${ban ? 'ban' : 'unban'} failed!`);
                    }
                } catch (err) {
                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-close';
                    this.snackbar.text = err.message || 'An unknown error occurred: ' + err;
                    this.snackbar.state = true;
                }
            },

            confirmDeleteUser(user) {
                const confirm = window.confirm(`Are you sure you want to delete ${user.Username}? This cannot be undone.`);
                if (confirm) this.deleteUser(user);
            },

            async deleteUser(user) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.delete(`${config.API_LOCATION}/user/${user.ID}`, {
                        headers: axiosHeaders
                    });

                    if (response.data.success) {
                        let foundUser = _.findIndex(this.users, {ID: user.ID});
                        if (foundUser !== -1) this.users.splice(foundUser, 1);

                        this.snackbar.state = false;
                        this.snackbar.color = 'success';
                        this.snackbar.icon = 'mdi-check';
                        this.snackbar.text = `User was deleted successfully.`;
                        this.snackbar.state = true;
                    } else {
                        throw new Error(response.data.message || `User delete operation failed!`);
                    }
                } catch (err) {
                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-close';
                    this.snackbar.text = err.message || 'An unknown error occurred: ' + err;
                    this.snackbar.state = true;
                }
            },

            async viewSubscriptions(user) {
                this.dialogs.subscriptions.user = user;
                this.dialogs.subscriptions.state = true;
                this.dialogs.subscriptions.loading = true;
                this.dialogs.subscriptions.items = [];
                this.dialogs.subscriptions.noDataText = 'Loading subscriptions...';
                
                try {
                    if (subscriptionSource) {
                        subscriptionSource.cancel('Operation canceled due to new request.');
                        subscriptionSource = CancelToken.source();
                    }
                    
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;
                    
                    const response = await axios.get(`${config.API_LOCATION}/user/${user.ID}/subscriptions`, {
                        headers: axiosHeaders,
                        cancelToken: subscriptionSource.token
                    });
                    
                    this.dialogs.subscriptions.loading = false;
                    
                    if (response.data && response.data.success && Array.isArray(response.data.subscriptions)) {
                        this.dialogs.subscriptions.items = response.data.subscriptions;
                        this.dialogs.subscriptions.noDataText = 'No subscriptions found';
                    } else {
                        this.dialogs.subscriptions.noDataText = 'Error loading subscriptions';
                    }
                } catch (err) {
                    if (!axios.isCancel(err)) {
                        this.dialogs.subscriptions.loading = false;
                        this.dialogs.subscriptions.noDataText = 'Error loading subscriptions';
                        
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            console.error(err);
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-close';
                            this.snackbar.text = err.response?.data?.message || err.message || 'An unknown error occurred';
                            this.snackbar.state = true;
                        }
                    }
                }
            },
            
            closeSubscriptionsDialog() {
                this.dialogs.subscriptions.state = false;
                this.dialogs.subscriptions.user = null;
                this.dialogs.subscriptions.items = [];
            },
            
            getSubscriptionStatusColor(status) {
                switch (status) {
                    case 'active':
                        return 'success';
                    case 'canceled':
                        return 'error';
                    case 'incomplete':
                    case 'incomplete_expired':
                        return 'warning';
                    case 'past_due':
                        return 'orange';
                    case 'trialing':
                        return 'info';
                    case 'unpaid':
                        return 'red darken-4';
                    default:
                        return 'grey';
                }
            },
            
            formatCurrency(amount, currency) {
                if (!amount) return '-';
                
                // Convert from cents to dollars
                const value = parseFloat(amount) / 100;
                
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currency || 'USD'
                }).format(value);
            }
        },

        mounted() {

        }
    }
</script>

<style>
/* Using a global style to ensure it overrides Vuetify's styles */
.subscription-dialog-wrapper {
    position: absolute !important;
    top: 64px !important; /* Match your header height */
    left: 0 !important;
    height: calc(100vh - 64px) !important;
    width: 100% !important;
    max-height: none !important;
    max-width: none !important;
    margin: 0 !important;
}

/* Only apply the right offset on larger screens where the sidebar is visible */
@media (min-width: 1264px) {
    .subscription-dialog-wrapper {
        right: 256px !important; /* Match your sidebar width */
        width: calc(100% - 256px) !important;
    }
}

/* For medium screens where the sidebar might be collapsed or hidden */
@media (min-width: 960px) and (max-width: 1263px) {
    .subscription-dialog-wrapper {
        width: 100% !important;
        right: 0 !important;
    }
}
</style>

<style scoped>
.subscription-dialog {
    height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
}
</style>
